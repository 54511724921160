/*
 * @Author: Rise.Hao
 * @Date: 2022-10-22 23:30:53
 * @LastEditors: Rise.Hao
 * @LastEditTime: 2024-07-02 00:50:02
 * @Description: file content
 */
import { message } from "antd";
import axios from "axios"; // 引入axios
import config from "./config"; // 引入配置文件

const urlObj = {
    prod: config.baseUrl.prod, // 生产环境
    dev: config.baseUrl.dev, // 开发环境
};

const processUrl = process.env.REACT_APP_URL || "dev";
export const url = urlObj[processUrl] || config.baseUrl.dev;

const instance = axios.create({
    baseURL: url,
    timeout: 60000,
});

// 请求拦截器
instance.interceptors.request.use(
    function (config) {
        // console.log('发请求之前', config)
        if (localStorage.getItem("token")) {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    "token": localStorage.getItem("token"),
                },
            };
        }
        localStorage.removeItem("token");
        return config;
    },
    function (error) {
        console.log("请求错误", error);
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    function (response) {
        // console.log('得到的响应数据', response.data)
        if (response.data.code !== 200) {
            message.error('系统错误，请联系管理员')
        }
        return response;
    },
    function (error) {
        console.log("响应错误", error);
        return Promise.reject(error);
    }
);

// get请求
export function get(url, data = {}) {
    return new Promise((resolve, reject) => {
        instance
            .get(url, {
                params: data,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

// post请求
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();

        // 将数据添加到 FormData 对象
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(
            (response) => {
                resolve(response);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export const returnMsg = async (promise) => {
    try {
        const res = await promise;
        const { data, status } = res;
        if (status === 200) {
            return data;
        } else {
            localStorage.removeItem("token");
            window.location.reload();
            console.log("no 200 !");
            return { code: 0, msg: "string", result: [] };
        }
    } catch (e) {
        localStorage.removeItem("token");
        window.location.reload();
        console.log("error");
        return { code: 0, msg: "string", result: [] };
    }
};
