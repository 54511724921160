/*
 * @Author: Rise.Hao
 * @Date: 2023-01-08 14:16:21
 * @LastEditors: Simoon.jia
 * @LastEditTime: 2023-12-09 14:46:33
 * @Description: file content
 */
import { get, post, returnMsg } from "./request/index";

/**
 * 获取appid
 *
 */
export const getAppId = () => returnMsg(get("/login"));

/**
 * 获取登陆二维码
 * @param data
 */
export const getLoginWeChat = (data) => returnMsg(get("/ucenter/wx/callback?code=" + data));

/**
 * 获取用户信息
 * @param data
 */
export const getUserInfo = () => returnMsg(get("/member/getAccountUser"));

/**
 * 登陆功能
 * @param data
 */
export const login = (data) => returnMsg(post("/userAdmin/login", data));
