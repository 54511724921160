/*
 * @Author: Rise.Hao
 * @Date: 2022-10-22 21:44:53
 * @LastEditors: Simoon.jia
 * @LastEditTime: 2023-12-09 14:48:33
 * @Description: file content
 */
import { makeObservable, observable, action } from 'mobx';
import { login } from "../../../API/login";
import { message } from "antd"


class Store {
    // token: string = localStorage.getItem('token') || '';
    loginLoading = false;
    constructor() {
        makeObservable(this, {
            // getRegionTree: action,
            // addressList: observable,
            // addressLoading: true,
        });
    }

    loginAPI = async (payload) => {
        this.loginLoading = true;
        const res = await login(payload);
        if (res.code === 200) {
            console.log(res);
            localStorage.setItem("name", res.data.username);
            localStorage.setItem("token", res.data.token);
            message.success('登陆成功');
            // localStorage.setItem("name", payload.username);
            // localStorage.setItem("token", res.result);
            window.location.reload();
        } else {
            message.error(res.msg);
        }
        this.loginLoading = false;
    }
}
const IndexStore = new Store()
export default IndexStore;