/*
 * @Author: Rise.Hao
 * @Date: 2023-11-17 21:32:46
 * @LastEditors: Rise.Hao
 * @LastEditTime: 2024-03-03 14:56:04
 * @Description: file content
 */

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { routerList, redirect } from "./router.config";
import Header from "./Layouts/Header/index";
import Login from "./pages/Login";
// import OrderPhone from './pages/OrderPhone'
import zh_CN from "antd/lib/locale-provider/zh_CN";
import { ConfigProvider } from "antd";
function Router() {
    // const location = useLocation();
    // const currentPath = location.pathname;
    return (
        <Header>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    {routerList.map((item) => {
                        return (
                            <Route
                                key={item.key}
                                path={item.key}
                                element={React.createElement(item.element)}
                            />
                        );
                    })}

                    <Route
                        path={redirect.key}
                        element={<Navigate to={redirect.redirect} />}
                    />
                </Routes>
            </Suspense>
        </Header>
    );
}

const App = () => {
    const checkLoginResult = localStorage.getItem("token");
    return checkLoginResult ? (
        Router()
    ) : (
        <Suspense>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </Suspense>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <ConfigProvider locale={zh_CN}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ConfigProvider>
    </React.StrictMode>
);
