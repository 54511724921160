/*
 * @Author: Rise.Hao
 * @Date: 2022-10-22 23:34:38
 * @LastEditors: Rise.Hao
 * @LastEditTime: 2024-07-03 01:20:43
 * @Description: file content
 */
const config = {
    baseUrl: {
        // 开发环境
        dev: 'http://8.146.201.40:8090',
        // dev: 'http://127.0.0.1:8090',
        // dev: 'http://liuyi.ifast3.vipnps.vip/api/v1',
        // 生产环境
        prod: 'http://8.146.201.40:8090'
    }
}
export default config;