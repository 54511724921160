/*
 * @Author: Rise.Hao
 * @Date: 2022-10-02 01:02:13
 * @LastEditors: Rise.Hao
 * @LastEditTime: 2024-03-03 14:57:30
 * @Description: file content
 */
import React from 'react'
export const router = [
    {
        key: '/home',
        label: '首页',
        element: React.lazy(() => import('./pages/Home')),
    },
    {
        key: '/index',
        label: '首页',
        element: React.lazy(() => import('./pages/Index')),
    },
    {
        key: "/flowers",
        label: "商品中心",
        children: [
            {
                key: "/list",
                label: "商品列表",
                element: React.lazy(() => import("./pages/Flowers")),
            },
        ],
    },
    {
        key: '/orderPhone',
        label: '首页',
        element: React.lazy(() => import('./pages/OrderPhone')),
    },
    {
        key: "/orders",
        label: "订单中心",
        children: [
            {
                key: "/ordersList",
                label: "订单列表",
                element: React.lazy(() => import("./pages/Orders")),
            }, {
                key: "/completedOrder",
                label: "已完成订单",
                element: React.lazy(() => import("./pages/Orders/completedOrder")),
            }, {
                key: "/incompleteOrder",
                label: "未完成订单",
                element: React.lazy(() => import("./pages/Orders/incompleteOrder")),
            },
        ],
    },
];

const flatMethod = (arr, value, key = "") => {
    return [].concat(
        ...arr.map((item) => {
            return [].concat({ ...item, key: key + item.key, path: item.key }, ...flatMethod(item[value] || [], value, key + item.key));
        })
    );
};

const elementFunc = (item) => {
    const { children, element } = item;
    if (element) {
        const obj = { ...item };
        if (children) delete obj.children;
        return obj;
    }
    return {
        ...item,
        children: children.map((_item) => {
            return elementFunc(_item);
        }),
    };
};

export const leftMenuRouter = router.map((item) => elementFunc(item));
export const routerList = flatMethod(router, "children").filter((item) => !item.children || item.element);
export const redirect = { key: "*", redirect: "/home" };
// export const redirect = { path: '*', exact: true, redirect: '/' }