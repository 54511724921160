/*
 * @Author: Rise.Hao
 * @Date: 2022-11-05 20:28:35
 * @LastEditors: Rise.Hao
 * @LastEditTime: 2024-07-02 00:49:49
 * @Description: file content
 */

import React from "react";
// import LeftMenu from "./LeftMenu";
// import BreadcrumbDom from "./BreadcrumbDom";
import { useNavigate } from "react-router-dom";
import { Layout, Dropdown } from "antd";
import styles from "./assets/index.less";
// import { router } from "@/router.config";
const { Header, Content } = Layout;


const App = (props) => {
    const { children } = props;
    const navigate = useNavigate();
    /**
     * 退出登陆
     */
    const exitLoginFunc = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    const items = [
        {
            key: "1",
            label: <div onClick={exitLoginFunc}>退出登陆</div>,
        },
    ];

    return (
        <Layout>
            <Header className={styles.header}>
                <div className={styles.left} onClick={()=>{navigate('/home')}}>
                    {/* <img className={styles.logo} src={Icon} alt="" /> */}
                    首页
                    {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["2"]} items={items1} /> */}
                </div>
                <div className={styles.userInfo}>
                    <Dropdown menu={{ items }} placement="bottom">
                        <div className={styles.name}>退出登录</div>
                    </Dropdown>
                </div>
            </Header>
            <Layout>
                {/* <Sider width={200} className={styles.leftSider}>
					<LeftMenu />
				</Sider> */}
                {/* <Layout style={{ padding: "0 24px 0px" }}> */}
                {/* <BreadcrumbDom /> */}
                <Content className={styles.content}>{children}</Content>
                <span className={styles.footer}>Copyright © 2023 英健园林管理后台 <br />Systems Incorporated. All rights reserved.</span>
                {/* </Layout> */}
            </Layout>
        </Layout>
    );
};

export default App;
