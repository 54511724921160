/*
 * @Author: Rise.Hao
 * @Date: 2022-10-02 01:03:25
 * @LastEditors: Rise.Hao
 * @LastEditTime: 2024-03-03 14:53:31
 * @Description: file content
 */
import React, { useContext, createContext } from "react";
import { Button, Form, Input } from "antd";
// import { useNavigate } from "react-router-dom";
import styles from "./assets/index.less";
import IndexStore from "./models/index";
import { observer } from "mobx-react-lite";
const Store = createContext(IndexStore);

const Index = () => {
	// const navigate = useNavigate();
	const { loginAPI } = useContext(Store);
	const onFinish = (values) => {
		const { username, password } = values;
		loginAPI({
			username: username,
			password: password,
			userType: "rise",
		});
		console.log("Success:", values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className={styles.box}>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item label="用户" name="username" rules={[{ required: true, message: "请输入用户名！" }]}>
					<Input />
				</Form.Item>

				<Form.Item label="密码" name="password" rules={[{ required: true, message: "请输入密码！" }]}>
					<Input.Password />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit">
						登陆
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default observer(Index);
